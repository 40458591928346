main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

main div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

main form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

main a{
    text-decoration: none;
    margin: 0rem 0.5rem;
    color: var(--branco);
    padding-bottom: 2rem;
    width: 50%;
    text-align: right;
}

main a:hover{
    text-decoration: underline;
}


main p{
    text-decoration: none;
    margin: 0rem 0.5rem;
    color: var(--branco);
    padding-bottom: 2rem;
    font-size: 1.2rem;
    padding: 3rem 0 2rem 0 ;
    text-align: center;
    width: 40%;
}