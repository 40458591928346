.button {
    background-color: transparent;
    border: 0.15rem solid var(--branco);
    border-radius: 0.25rem;
    font-family: "Metrophobic", sans-serif;
    color: var(--branco);
    font-size: 1rem;
    margin: 2rem;
    padding: 1rem 2.5rem;
    cursor: pointer;
    flex-grow: 1;
}

.button:hover{
    background-color: rgba(255, 255, 255, 0.3); 
}