.bi__report__main{
    display: flex;
    align-items: center;
    height: 100vh;
}

.bi__report{
    margin: 3rem;
    width: 90%;
    overflow: hidden;
    max-height: 87vh;
    border: 1rem solid var(--branco);
    border-radius: 1rem;
}


.bi__report iframe{
    background-color: transparent;
    width: 100%;
    min-height: 95vh;
    margin-top: -1%;
}


.bi__report__div{
    padding-top: 5rem;
}

.bi__report__div h1{
    font-size: 2.5rem;
    padding-bottom: 2rem;
    text-align: center;
}

.bi__report__div p{
    font-size: 1.2rem;
    width: 80%;
}

.bi_button{
    background-color: transparent;
    text-align: center;
}

.bi_button select{
    border: 0.15rem solid var(--branco);
    border-radius: 0.25rem;
    font-family: "Metrophobic", sans-serif;
    font-size: 1rem;
    color: var(--branco);
    cursor: pointer;
    padding: 1rem 2rem;
    
}

.bi_button select option{
    color: var(--azul-escuro);
    padding: 1rem;   
}


.bi_button select:focus{
    outline: None;  
}