.field{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 2rem;
}

.field label{
    font-size: 1rem;
    padding: 1rem 0 0.7rem 0.7rem;
}

.field input{
    flex-grow: 1;
    background-color: transparent;
    border: 0.1rem solid var(--branco);
    border-radius: 0.25rem;
    font-size: 1rem;
    margin: 1rem;
    padding: 0.5rem;
    color: var(--branco);
    width: 100%;
}

.field input:focus{
    outline: None;
}

.field input::placeholder{
    color: var(--branco);
}