header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 1rem 1rem 1rem;
}

header img {
    width: 4rem;
}

header button {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.header__img_logout {
    width: 2rem;
}


.header-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-paragraph {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}