footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0.5rem 0 0.5rem;
    position: relative;
    bottom: 0;
}

footer .div-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    border-top: 1px solid var(--branco);
    border-bottom: 1px solid var(--branco);
}

footer .div-img{
    display: flex;
    text-align: center;
    padding-top: 2rem;
}

footer ul {
    display: flex;
    align-items: center;
}


footer div address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

footer a {
    text-decoration: none;
    color: var(--branco);
    position: relative;
    font-size: 0.8rem;
}

footer a:hover {
    text-decoration: underline;
}


footer p {
    text-decoration: none;
    color: var(--branco);
    padding: 0.2rem;
    font-size: 0.8rem;
}

footer img {
    width: 2.5rem;
}

.footer__social_media {
    width: 1.5rem;
    padding: 0 0.2rem;
}